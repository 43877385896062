import { DialogConfig, dialogStore, DialogType } from "./dialog.store";

export default class DialogService {
  static showConfirm({
    titleString = "Element löschen",
    questionString = "Wollen Sie das Element wirklich löschen?",
    onSubmit,
    onCancel,
    dialogType = DialogType.warning,
  }: {
    titleString?: String;
    questionString?: String;
    dialogType?: DialogType;
    onSubmit: () => void;
    onCancel: () => void;
  }): void {
    const config: DialogConfig = {
      title: titleString,
      body: questionString,
      submitLabel: "OK",
      cancelLabel: "Abbrechen",
      cancelCallback: () => {
        dialogStore.hideDialog();
        onCancel();
      },
      submitCallback: () => {
        dialogStore.hideDialog();
        onSubmit();
      },
      dialogType: dialogType,
    };
    dialogStore.configDialog(config);
    dialogStore.showDialog();
  }
}
