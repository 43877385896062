import { Subject } from "rxjs";
import FavoriteModel from "../models/favorite.model";
import React from "react";

const subject = new Subject<FavoriteState>();

interface FavoriteState {
  favorite: FavoriteModel | null;
}

const initialState: FavoriteState = {
  favorite: null,
};

let state = initialState;

export const favoritesStore = {
  subscribe: (
    setFavoriteState: React.Dispatch<React.SetStateAction<FavoriteState>>,
  ) => subject.subscribe((state) => setFavoriteState(state)),
  initialState,
  setFavorite: (favorite: FavoriteModel) => {
    state = {
      favorite: favorite,
    };
    subject.next(state);
  },
  getCurrentFavorite: () => {
    return state.favorite;
  }
};
