import SubcategoryModel from "../../../models/subcategory.model";

export default function SubcategoryModalBody({
  editObj,
  setEditObj,
}: {
  editObj: SubcategoryModel;
  setEditObj: Function;
}) {
  return (
    <div className="input-group mb-3">
      <span className="input-group-text" id="basic-addon1">
        Name
      </span>
      <input
        type="text"
        className="form-control"
        placeholder="Subkategorie"
        value={editObj.name}
        onChange={(e) =>
          setEditObj({ ...editObj, name: e.target.value } as SubcategoryModel)
        }
      />
    </div>
  );
}
