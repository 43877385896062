export default function DeleteQuestion({
  setShowDeleteQuestion,
  deleteCallback,
}: {
  setShowDeleteQuestion: Function;
  deleteCallback: Function;
}) {
  return (
    <div className="row w-100">
      <div className="col-12 px-0">
        <div className="alert alert-danger" role="alert">
          <div className="row">
            <div className="col-12">
              Vorsicht! Möchten Sie dieses Element und sämtlichen Inhalt
              wirklich löschen?
            </div>
          </div>
          <div className="col-12 pt-2 ">
            <button
              className="btn btn-outline-secondary me-2"
              onClick={() => setShowDeleteQuestion(false)}
            >
              Abbrechen
            </button>
            <button
              className="btn btn-outline-danger"
              onClick={() => deleteCallback()}
            >
              Löschen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
