import React, { Fragment, useEffect, useState } from "react";
import TextBlockModel from "../../models/text-block.model";
import NothingToDisplay from "../shared/nothing-to-display.component";
import { useDrop } from "react-dnd";
import TextBlockDraggableComponent, {
  DragItem,
} from "./text-block-draggable.component";
import ClipboardService from "../../services/clipboard.service";
import ToastService from "../../services/toast.service";
import { textBlocksEditorStore } from "../../state/text-blocks/text-blocks-editor.store";
import TextBlocksEditorService from "../../state/text-blocks/text-blocks-editor.service";
import { DraggableTypes } from "../../models/draggable-types.model";
import { v4 } from "uuid";

function TextBlocksEditorComponent({}) {
  const [textBlockEditorState, setTextBlockEditorState] = useState(
    textBlocksEditorStore.initialState,
  );
  const [isExtended, setIsExtended] = useState<boolean>(true);

  useEffect(() => {
    const subscription = textBlocksEditorStore.subscribe(
      setTextBlockEditorState,
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const [{ isOver }, dropRef] = useDrop<DragItem, void, { isOver: boolean }>({
    accept: DraggableTypes.textBlock,
    collect: (monitor) => ({ isOver: monitor.isOver() }),
    drop(dragItem: DragItem, monitor) {
      TextBlocksEditorService.dropTextBlockByIndex(dragItem.index, false);
    },
  });

  function moveTextBlock(dragIndex: number, hoverIndex: number) {
    TextBlocksEditorService.moveTextBlock(dragIndex, hoverIndex);
  }

  function copyText() {
    const textToCopy: string = textBlocksEditorStore
      .getTextBlocks()
      .map((textBlockModel) => textBlockModel.text)
      .join("\n");

    ClipboardService.copyToClipboard(textToCopy).subscribe(
      (result: boolean) => {
        if (result) {
          ToastService.success(`Inhalt wurde kopiert!`);
        } else {
          ToastService.error(`Inhalt konnte nicht kopiert werden!`);
        }
      },
    );
  }

  return (
    <>
      <div
        style={{ height: "calc(100vh - 8rem)" }}
        className={"sticky-top pt-2"}
      >
        <div className="border rounded pt-3 text-blocks--wrapper h-100">
          <div className="d-flex flex-wrap">
            <button
              type="button"
              className="btn btn-sm btn-outline-success ms-2 me-2"
              onClick={() => copyText()}
              data-bs-toggle="tooltip"
              data-bs-title="Gesamten Text kopieren"
            >
              <i className="bi-clipboard" />
            </button>
            <label className="btn btn-sm btn-outline-secondary me-2">
              <i className="bi-arrow-repeat me-1"></i>
              {isExtended ? "Titel anzeigen" : "Text anzeigen"}
              <input
                type="checkbox"
                className="btn-check"
                onChange={() => setIsExtended(!isExtended)}
              />
            </label>
            <button
              className={
                "btn btn-sm btn-outline-danger trash-button " +
                (isOver ? "is-drag-target" : "")
              }
              style={{ flex: 1 }}
              ref={dropRef}
              onClick={() => TextBlocksEditorService.clearStore(true)}
              data-bs-toggle="tooltip"
              data-bs-title="Textblöcke durch Klicken oder Hineinziehen entfernen."
            >
              <i className="bi-trash"></i>
            </button>
          </div>

          <div className={"h-100 pt-2"}>
            <hr className="m-0 mt-2" />
            <div
              className="small font-monospace lh-base h-100 pt-4"
              style={{ overflowY: "scroll" }}
            >
              {textBlockEditorState.textBlocks.length === 0 && (
                <NothingToDisplay />
              )}

              {textBlockEditorState.textBlocks.length > 0 &&
                textBlockEditorState.textBlocks.map(
                  (textBlock: TextBlockModel, index: number) => (
                    <TextBlockDraggableComponent
                      textBlock={textBlock}
                      index={index}
                      key={v4()}
                      moveTextBlock={moveTextBlock}
                      isExtended={isExtended}
                    />
                  ),
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TextBlocksEditorComponent;
