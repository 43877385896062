import {Popover, Tooltip} from "bootstrap";
import {useEffect, useState} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {useLocation} from "react-router-dom";
import CategoriesList from "../components/categories-list.component";
import DialogContainerComponent from "../components/dialog/dialog-container.component";
import ModalContainer from "../components/modal/modal-container.component";
import CreateBtn from "../components/shared/create-btn.component";
import TextBlocksEditorComponent from "../components/text-block-editor/text-blocks-editor.component";
import CategoryModel from "../models/category.model";
import ModelService from "../services/model.service";
import {categoriesStore} from "../store/categories";
import {modalStore} from "../store/modal.store";
import {DebounceInput} from "react-debounce-input";
import {MultiSelect, Option} from "react-multi-select-component";
import FavoritesList from "../components/favorites/favorites-list.component";
import {favoritesStore} from "../store/favorites";
import {useAuth} from "react-oidc-context";

function EditorPage() {
  const auth = useAuth();
  const [favoritesState, setFavoritesState] = useState(
      favoritesStore.initialState,
  );
  const [categoriesState, setCategoriesState] = useState(
    categoriesStore.initialState,
  );
  const [searchString, setSearchString] = useState("");
  const [modalState, setModalState] = useState(modalStore.initialState);
  const [searchContextCategories, setSearchContextCategories] = useState<
    Option[]
  >([]);

  const multiSelectTranslations: { [key: string]: string } = {
    allItemsAreSelected: "Alle Kategorien wurden ausgewählt",
    clearSearch: "Suche löschen",
    clearSelected: "Auswahl entfernen",
    noOptions: "Keine Kategorien verfügbar",
    search: "Nach Kategorie suchen",
    selectAll: "Alle auswählen",
    selectAllFiltered: "Alle gefilterten auswählen",
    selectSomeItems: "Kategorien auswählen ...",
    create: "Erstellen",
  };

  let location = useLocation();
  const editModeEnabled = location.pathname === "/edit";

  // Enable global tooltips
  const tooltipTriggerList: NodeListOf<HTMLElement> = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]',
  );
  const tooltipList: Tooltip[] = [...tooltipTriggerList].map(
    (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl),
  );

  // Enable global popovers
  const popoverTriggerList: NodeListOf<HTMLElement> = document.querySelectorAll(
    '[data-bs-toggle="popover"]',
  );
  const popoverList: Popover[] = [...popoverTriggerList].map(
    (popoverTriggerEl) => new Popover(popoverTriggerEl),
  );

  useEffect(() => {
    ModelService.getAllCategoriesPopulatedFilteredShowSubcategories(
      searchString,
      searchContextCategories.map((option) => option.value),
    ).then((res) => categoriesStore.setCategories(res));
  }, [searchString, searchContextCategories]);

  useEffect(() => {
    const subscription = categoriesStore.subscribe(setCategoriesState);
    const modalSubscription = modalStore.subscribe(setModalState);
    const favoriteSubscription = favoritesStore.subscribe(setFavoritesState);
    ModelService.getAllCategories().then((res) =>
      categoriesStore.setAllCategories(res),
    );
    //sub is userId in keycloak
    if (auth?.user?.profile?.sub) {
      ModelService.getFavoriteByUserId(auth.user.profile.sub).then((res) => {
        favoritesStore.setFavorite(res);
      })
    }

    return () => {
      subscription.unsubscribe();
      modalSubscription.unsubscribe();
      favoriteSubscription.unsubscribe();
    };
  }, []);

  function clearSearch() {
    setSearchString("");
    setSearchContextCategories([]);
  }

  function buildCategoryOptions(
    models: CategoryModel[],
  ): { value: string; label: string; disabled: false }[] {
    return models.map((model: CategoryModel) => ({
      value: model.id!,
      label: model.name,
      disabled: false,
    }));
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row p-2">
          <div className="col-8">
            <div className={"mb-4 pt-2 sticky-top"}>
              <label className="visually-hidden" htmlFor="searchField">
                Durchsuchen
              </label>
              <div className="input-group">
                <div className="input-group-text">
                  <i className="bi-binoculars"></i>
                </div>
                <DebounceInput
                  debounceTimeout={500}
                  placeholder="Durchsuchen"
                  className="form-control"
                  id="searchField"
                  value={searchString}
                  onChange={(event) => setSearchString(event.target.value)}
                />

                <MultiSelect
                  value={searchContextCategories}
                  className="form-control"
                  labelledBy="Select"
                  options={buildCategoryOptions(categoriesState.allCategories)}
                  onChange={setSearchContextCategories}
                  hasSelectAll={false}
                  overrideStrings={multiSelectTranslations}
                />

                <div className="input-group-text">
                  <button className="btn btn-sm" onClick={() => clearSearch()}>
                    <i className="bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
            {!editModeEnabled && (
              <div className="ps-2 pe-2 pb-2">
                <FavoritesList
                    favorite={favoritesState.favorite}
                />
              </div>
            )}
            {searchString !== "" && (
                <p>
                  <strong>Gefilterte Ergebnisse für "{searchString}".</strong>
                </p>
            )}
            <div className="ps-2 pe-2">
              <CategoriesList
                categories={categoriesState.categories}
                searchString={searchString}
                editMode={editModeEnabled}
              />
            </div>
            {modalState.modalObj && <ModalContainer modalState={modalState} />}
            {editModeEnabled && (
              <CreateBtn text={"+ Kategorie"} newObj={new CategoryModel()} />
            )}
          </div>
          <div className="col-4">
            <DndProvider backend={HTML5Backend}>
              <TextBlocksEditorComponent />
            </DndProvider>
          </div>
          <DialogContainerComponent />
        </div>
      </div>
    </>
  );
}

export default EditorPage;
