import { modalStore } from "../../store/modal.store";
import CategoryModel from "../../models/category.model";
import React from "react";
import SubcategoryModel from "../../models/subcategory.model";
import TextBlockModel from "../../models/text-block.model";

export default function CreateBtn({
  text,
  newObj,
}: {
  text: string;
  newObj: CategoryModel | SubcategoryModel | TextBlockModel;
}) {
  return (
    <div className="row">
      <div className="col-12 pt-4">
        <button
          type="button"
          className="btn btn-outline-success w-100"
          onClick={() => modalStore.setModalObj(newObj)}
        >
          {text}
        </button>
      </div>
    </div>
  );
}
