import TextBlockModel from "../../../models/text-block.model";

export default function TextBlockModalBody({
  editObj,
  setEditObj,
}: {
  editObj: TextBlockModel;
  setEditObj: Function;
}) {
  return (
    <>
      <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon1">
          Titel
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="Titel"
          value={editObj.title}
          onChange={(e) =>
            setEditObj({ ...editObj, title: e.target.value } as TextBlockModel)
          }
        />
      </div>
      <div className="form-floating">
        <textarea
          className="form-control"
          style={{ height: "200px" }}
          placeholder="Text"
          id="floatingTextarea"
          value={editObj.text}
          onChange={(e) =>
            setEditObj({ ...editObj, text: e.target.value } as TextBlockModel)
          }
        />
        <label htmlFor="floatingTextarea">Text</label>
      </div>
    </>
  );
}
