import "./App.scss";

import { NavLink, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import EditorPage from "./pages/editor.page";
import ChangelogPage from "./pages/changelog.page";
import ChangelogService from "./services/changelog.service";
import { useEffect, useState } from "react";
import LocalStorageService from "./services/local-storage.service";
import { useAuth } from "react-oidc-context";
import ProtectedRoute from "./components/shared/protected-route.component";

function App() {
  const [showNewsBadge, setShowNewsBadge] = useState(true);
  const auth = useAuth();

  let userGroups: string[] | undefined = auth?.user?.profile?.user_group as
    | string[]
    | undefined;
  let userIsInEditGroup =
    Array.isArray(userGroups) && userGroups.includes("/edit");

  useEffect(() => {
    // Show the badge, if there is either no stored version or the displayed version is not outdated.
    const localVersion: string | null =
      LocalStorageService.instance.getStoredVersion();
    const isOutdated: boolean =
      localVersion === null ||
      ChangelogService.instance.isOutdatedVersion(localVersion);
    setShowNewsBadge(isOutdated);
  }, []);

  return (
    <div className="App">
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <span className="navbar-brand mb-0 h1">
            <NavLink reloadDocument to="/" className="nav-link">
              <i className="bi-keyboard me-1" />
              textowork
            </NavLink>
          </span>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink reloadDocument to="/edit" className="nav-link">
                  Editieren
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  reloadDocument
                  to="/changelog"
                  className="nav-link d-flex align-items-center"
                >
                  <span>Changelog</span>
                  {showNewsBadge && (
                    <span className="badge bg-info ms-1">Neu</span>
                  )}
                </NavLink>
              </li>
            </ul>
          </div>
          <button
            className="btn btn-outline-danger"
            type="button"
            onClick={() => {
              void auth.signoutRedirect({
                post_logout_redirect_uri: window.location.href,
              });
            }}
          >
            logout
          </button>
        </div>
      </nav>

      <div style={{ marginBottom: "3rem" }}>
        <Routes>
          <Route path="/" element={<EditorPage />} />
          <Route
            path="/edit"
            element={
              <ProtectedRoute redirectPath="/" isAllowed={userIsInEditGroup}>
                <EditorPage />
              </ProtectedRoute>
            }
          />
          <Route path="/changelog" element={<ChangelogPage />} />
        </Routes>
      </div>

      <footer className="fixed-bottom p-2 bg-white">
        <div className="w-100 w-100 d-flex justify-content-center align-items-center bg-body-tertiary">
          <span className="text-muted me-2">Made by Hogall AG</span>
          <span className="badge rounded-pill bg-danger me-2">
            <i className="bi-sunglasses fs-5" />
          </span>
          <span className="text-muted">
            Version {ChangelogService.instance.changelogFile.version}
          </span>
        </div>
      </footer>

      <ToastContainer />
    </div>
  );
}

export default App;
