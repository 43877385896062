import { Subject } from "rxjs";
import TextBlockModel from "../../models/text-block.model";

interface TextBlocksEditorState {
  textBlocks: TextBlockModel[];
}

const subject = new Subject<TextBlocksEditorState>();
const initialState: TextBlocksEditorState = {
  textBlocks: [],
};
let state: TextBlocksEditorState = initialState;

export const textBlocksEditorStore = {
  subscribe: (
    setTextBlocksToCopyState: React.Dispatch<
      React.SetStateAction<TextBlocksEditorState>
    >,
  ) => subject.subscribe((state) => setTextBlocksToCopyState(state)),

  initialState,

  getTextBlocks: () => state.textBlocks,

  dropTextBlockById: (id?: string) => {
    const index = state.textBlocks.findIndex((element) => element.id === id);
    if (id !== undefined && index > -1) {
      let updatedTextBlocks = [...state.textBlocks].splice(index, 1);
      state = { ...state, textBlocks: updatedTextBlocks };
      subject.next(state);
    }
  },

  /**
   * Appends the provided textblock at the store array end.
   *
   * @param textBlock
   */
  addTextBlock: (textBlock: TextBlockModel) => {
    state = {
      ...state,
      textBlocks: [...state.textBlocks, textBlock],
    };
    subject.next(state);
  },

  /**
   *
   * @param fromIndex
   * @param toIndex
   */
  moveTextBlock: (fromIndex: number, toIndex: number) => {
    let reorderedTextBlocks = [...state.textBlocks];
    reorderedTextBlocks.splice(
      toIndex,
      0,
      reorderedTextBlocks.splice(fromIndex, 1)[0],
    );
    state = { ...state, textBlocks: reorderedTextBlocks };
    subject.next(state);
  },

  /**
   * Resets the editor content to the initial state
   */
  clearTextBlocks: () => {
    state = initialState;
    subject.next(initialState);
  },

  /**
   * Removes the item by index from the store.
   *
   * @param index
   */
  dropTextBlockByIndex(index: number) {
    if (index > -1 && index < state.textBlocks.length) {
      let updatedTextBlocks = [...state.textBlocks];
      updatedTextBlocks.splice(index, 1);
      state = { ...state, textBlocks: updatedTextBlocks };
      subject.next(state);
    }
  },
};
