import {Expose, Type} from "class-transformer";
import BaseModel from "./base.model";
import TextBlockModel from "./text-block.model";

/**
 * Class represents a favorite.
 * @extends BaseModel
 */
export default class FavoriteModel extends BaseModel {
  className = "FavoriteModel";

  @Expose({ name: "userId" })
  userId = "";

  @Expose()
  @Type(() => TextBlockModel)
  textBlockIds: TextBlockModel[] = [];
}
