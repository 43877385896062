export default class ColorService {
  static colors: string[] = [
    "#fa1b23",
    "#e91e63",
    "#c731e0",
    "#b69be0",
    "#97a2f7",
    "#569efc",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#36f035",
    "#8bc34a",
    "#afb42b",
    "#ff9800",
    "#ff5722",
    "#e8a087",
    "#9fbcc9",
  ];

  /**
   * Color from array magic taken from https://gist.github.com/0x263b/2bdd90886c2036a1ad5bcf06d6e6fb37#color-from-array
   *
   * @param inputString
   */
  static getHexColorByString(inputString: string) {
    let hash = 0;
    if (inputString.length === 0) {
      return hash.toString(16);
    }
    for (let i = 0; i < inputString.length; i++) {
      hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }
    hash =
      ((hash % this.colors.length) + this.colors.length) % this.colors.length;
    return this.colors[hash];
  }
}
