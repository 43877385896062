import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import CategoryModel from "../models/category.model";
import SubcategoryModel from "../models/subcategory.model";
import TextBlockModel from "../models/text-block.model";
import FavoriteApiModel from "../models/favorite.api.model";

export default class ApiService {
  static baseUrl = process.env.REACT_APP_API_URL;

  static getAllCategories(): Promise<Object[]> {
    return axios
      .get(`${this.baseUrl}/api/categories`)
      .then(this.handleResponse)
      .catch(this.handleError) as Promise<Object[]>;
  }

  static getAllCategoriesPopulated(): Promise<Object[]> {
    return axios
      .get(`${this.baseUrl}/api/categories-populated`)
      .then(this.handleResponse)
      .catch(this.handleError) as Promise<Object[]>;
  }

  static getAllCategoriesPopulatedFiltered(
    searchString: String,
  ): Promise<Object[]> {
    return axios
      .get(
        `${this.baseUrl}/api/categories-populated-filtered?query=${searchString}`,
      )
      .then(this.handleResponse)
      .catch(this.handleError) as Promise<Object[]>;
  }

  static getAllCategoriesPopulatedFilteredShowSubcategories(
    searchString: String,
    searchContextCategories: String[],
  ): Promise<Object[]> {
    const categoriesParams = searchContextCategories.join(",");
    return axios
      .get(`${this.baseUrl}/api/categories-populated-filtered-subcategories`, {
        params: {
          query: searchString,
          categories: categoriesParams,
        },
      })
      .then(this.handleResponse)
      .catch(this.handleError) as Promise<Object[]>;
  }

  static getAllSubcategories(): Promise<Object[]> {
    return axios
      .get(`${this.baseUrl}/api/subcategories`)
      .then(this.handleResponse)
      .catch(this.handleError) as Promise<Object[]>;
  }

  static getAllTextBlocks(): Promise<Object[]> {
    return axios
      .get(`${this.baseUrl}/api/text-blocks`)
      .then(this.handleResponse)
      .catch(this.handleError) as Promise<Object[]>;
  }

  static createCategory(category: CategoryModel): Promise<Object> {
    return axios
      .post(`${this.baseUrl}/api/categories`, category)
      .then(this.handleResponse)
      .catch(this.handleError) as Promise<Object>;
  }

  static createSubcategory(subcategory: SubcategoryModel): Promise<Object> {
    return axios
      .post(`${this.baseUrl}/api/subcategories`, subcategory)
      .then(this.handleResponse)
      .catch(this.handleError) as Promise<Object>;
  }

  static createTextBlock(textBlock: TextBlockModel): Promise<Object> {
    return axios
      .post(`${this.baseUrl}/api/text-blocks`, textBlock)
      .then(this.handleResponse)
      .catch(this.handleError) as Promise<Object>;
  }

  static updateCategory(category: CategoryModel): Promise<Object> {
    return axios
      .post(`${this.baseUrl}/api/categories/${category.id}`, category)
      .then(this.handleResponse)
      .catch(this.handleError) as Promise<Object>;
  }

  static updateSubcategory(subcategory: SubcategoryModel): Promise<Object> {
    return axios
      .post(`${this.baseUrl}/api/subcategories/${subcategory.id}`, subcategory)
      .then(this.handleResponse)
      .catch(this.handleError) as Promise<Object>;
  }

  static updateTextBlock(textBlock: TextBlockModel): Promise<Object> {
    return axios
      .post(`${this.baseUrl}/api/text-blocks/${textBlock.id}`, textBlock)
      .then(this.handleResponse)
      .catch(this.handleError) as Promise<Object>;
  }

  static deleteCategory(category: CategoryModel): Promise<Object> {
    const config: AxiosRequestConfig = { data: category.toJSON() };
    return axios
      .delete(`${this.baseUrl}/api/categories/${category.id}`, config)
      .then(this.handleResponse)
      .catch(this.handleError) as Promise<Object>;
  }

  static deleteSubcategory(subcategory: SubcategoryModel): Promise<Object> {
    const config: AxiosRequestConfig = { data: subcategory.toJSON() };
    return axios
      .delete(`${this.baseUrl}/api/subcategories/${subcategory.id}`, config)
      .then(this.handleResponse)
      .catch(this.handleError) as Promise<Object>;
  }

  static deleteTextBlock(textBlock: TextBlockModel): Promise<Object> {
    const config: AxiosRequestConfig = { data: textBlock.toJSON() };
    return axios
      .delete(`${this.baseUrl}/api/text-blocks/${textBlock.id}`, config)
      .then(this.handleResponse)
      .catch(this.handleError) as Promise<Object>;
  }

  static getFavoriteByUserId(userId: string): Promise<Object> {
    return axios
        .get(`${this.baseUrl}/api/favorites/${userId}`)
        .then(this.handleResponse)
        .catch(this.handleError) as Promise<Object>
  }
  static addOrUpdateFavorite(favorite: FavoriteApiModel): Promise<Object> {
    return axios
        .post(`${this.baseUrl}/api/favorites`, favorite)
        .then(this.handleResponse)
        .catch(this.handleError) as Promise<Object>;
  }
  static deleteFavoriteByUserIdAndTextBlockId(favorite: FavoriteApiModel): Promise<Object> {
    const config: AxiosRequestConfig = { data: favorite.toJSON() };
    return axios
        .delete(`${this.baseUrl}/api/favorites`, config)
        .then(this.handleResponse)
        .catch(this.handleError) as Promise<Object>;
  }

  private static handleResponse(response: AxiosResponse): Object | Object[] {
    if (response.status === 200) {
      return Array.isArray(response.data)
        ? (response.data as Object[])
        : (response.data as Object);
    }
    throw new Error(response.statusText);
  }

  private static handleError(error: AxiosError) {
    if (error.response) {
      console.log(error.response.data); // => the response payload
    }
  }
}
