import { BehaviorSubject } from "rxjs";

const initialState: DialogState = {
  displayModal: false,
  config: { title: "" },
};
const subject = new BehaviorSubject<DialogState>(initialState);

export enum DialogType {
  default,
  warning,
  error,
}

export interface DialogConfig {
  title?: String;
  body?: String | JSX.Element;
  cancelCallback?: () => void;
  submitCallback?: () => void;
  submitLabel?: String;
  cancelLabel?: String;
  dialogType?: DialogType;
}

export interface DialogState {
  displayModal: boolean;
  config: DialogConfig;
}

export const dialogStore = {
  subscribe: (
    setModalState: React.Dispatch<React.SetStateAction<DialogState>>,
  ) => subject.subscribe((state) => setModalState(state)),
  initialState,
  configDialog: (dialogConfig: DialogConfig) => {
    const state: DialogState = {
      displayModal: false,
      config: dialogConfig,
    };
    subject.next(state);
  },
  showDialog: () => {
    subject.next({ ...subject.getValue(), displayModal: true });
  },
  hideDialog: () => {
    subject.next({ ...subject.getValue(), displayModal: false });
  },
};
