import "reflect-metadata";

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import {AuthProvider, AuthProviderProps} from "react-oidc-context";
import Authentication from "./Authentication";
import {User} from "oidc-client-ts";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const onSigninCallback = (_user: User | void): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const oidcConfig: AuthProviderProps = {
  authority: "https://textowork.de/keycloak/realms/textowork-prod-realm",
  client_id: "textowork-client",
  //authority: "http://localhost:8080/realms/myrealm",
  //client_id: "myclient",
  redirect_uri: window.location.href,
  onSigninCallback: onSigninCallback,
};

root.render(
  <AuthProvider {...oidcConfig}>
    <BrowserRouter>
      <Authentication />
    </BrowserRouter>
  </AuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
