export default class StringUtilsService {
  /**
   * Returns a string that includes the specified open- and closing-tags before and after appearance of needle string.
   *
   * @param stringToHighlight
   * @param needle
   * @param tags
   */
  static getHighlightedString(
    stringToHighlight: string,
    needle: string = "",
    tags: { open: string; close: string } = {
      open: '<mark class="p-0">',
      close: "</mark>",
    },
  ): string {
    return stringToHighlight.replaceAll(
      new RegExp(needle, "gi"),
      `${tags.open}${needle}${tags.close}`,
    );
  }

  static getIndicesOfSubstring(haystack: string, needle: string): number[] {
    return [...haystack.matchAll(new RegExp(needle, "gi"))].map(
      (a) => a.index as number,
    );
  }

  static trimObjectAttributes<T>(object: T, attributes: (keyof T)[]): T {
    const trimmedObject = { ...object };

    attributes.forEach((attribute) => {
      const value = trimmedObject[attribute];
      if (typeof value === "string") {
        trimmedObject[attribute] = value.trim() as T[keyof T];
      }
    });

    return trimmedObject;
  }
}
