import React from "react";
import ModelService from "../../services/model.service";
import TextBlockModel from "../../models/text-block.model";
import {useAuth} from "react-oidc-context";
import FavoriteApiModel from "../../models/favorite.api.model";
import {favoritesStore} from "../../store/favorites";
import FavoriteModel from "../../models/favorite.model";
import {Tooltip} from "bootstrap";

const FavoriteBtn = ({textBlock, isFav}:{textBlock: TextBlockModel, isFav:boolean}) => {

    const auth = useAuth();

    const add = () => {
        if(auth?.user?.profile?.sub) {
            ModelService.addOrUpdateFavorite({userId: auth.user.profile.sub, textBlockId: textBlock.id} as FavoriteApiModel)
            .then((data) => {
                favoritesStore.setFavorite(data as FavoriteModel);
            })
        }
    }
    const remove = () => {
        if(auth?.user?.profile?.sub) {
            const favoriteInstance = new FavoriteApiModel(
                auth.user.profile.sub,
                textBlock.id!
            );
            ModelService.deleteFavoriteByUserIdAndTextBlockId(favoriteInstance)
            .then((data) => {
                favoritesStore.setFavorite(data as FavoriteModel);
            })
        }
    }

    return (
        <button
            type="button"
            className="btn btn-sm btn-outline-primary"
            data-bs-toggle="tooltip"
            data-bs-title={isFav ? `Von Favoriten entfernen` : `Zu Favoriten hinzufügen`}
            onClick={(event) => {
                // seems to be a hacky way to dispose tooltips otherwise they would be permanent.
                Tooltip.getInstance(event.currentTarget)?.dispose();
                isFav ? remove() : add();
            }}
        >
            <i className={isFav ? "bi-star-fill" : "bi-star"} />
        </button>
    );
}

export default FavoriteBtn;