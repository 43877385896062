import ChangelogService from "./changelog.service";

export default class LocalStorageService {
  private static _instance: LocalStorageService;

  private static readonly versionKey: string = "textoworkVersion";

  private constructor() {}

  public static get instance() {
    if (!LocalStorageService._instance) {
      LocalStorageService._instance = new LocalStorageService();
    }
    return LocalStorageService._instance;
  }

  public storeVersion(versionString: string): void {
    ChangelogService.instance.validateVersion(versionString);
    localStorage.setItem(LocalStorageService.versionKey, versionString);
  }

  public getStoredVersion(): string | null {
    return localStorage.getItem(LocalStorageService.versionKey);
  }
}
