import { useEffect, useState } from "react";
import DialogComponent from "./dialog.component";
import { DialogState, dialogStore } from "../../state/dialog/dialog.store";

export default function DialogContainerComponent() {
  const [dialogState, setDialogState] = useState<DialogState>(
    dialogStore.initialState,
  );
  useEffect(() => {
    const subscription = dialogStore.subscribe((state) => {
      setDialogState(state);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      {dialogState.displayModal && (
        <DialogComponent
          dialogConfig={dialogState.config}
          displayDialog={dialogState.displayModal}
        />
      )}
    </>
  );
}
