import CategoryModel from "../../models/category.model";
import SubcategoryModel from "../../models/subcategory.model";
import TextBlockModel from "../../models/text-block.model";
import CategoryModalBody from "./content/category-modal-body.component";
import ModelService from "../../services/model.service";
import { ModalState, modalStore } from "../../store/modal.store";
import { categoriesStore } from "../../store/categories";
import ToastService from "../../services/toast.service";
import SubcategoryModalBody from "./content/subcategory-modal-body.component";
import TextBlockModalBody from "./content/text-block-modal-body.component";
import { plainToInstance } from "class-transformer";

export function modalFactory(
  modalState: ModalState,
  editObj: CategoryModel | SubcategoryModel | TextBlockModel | null,
  setEditObj: Function,
) {
  let { modalObj } = modalState;

  let title, body, saveCallback, createCallback, deleteCallback;
  let titleStr = "";

  switch (modalObj?.className) {
    case "CategoryModel":
      titleStr = "Kategorie";
      body = (
        <CategoryModalBody
          editObj={editObj as CategoryModel}
          setEditObj={setEditObj}
        />
      );
      saveCallback = () => {
        ModelService.updateCategory(editObj as CategoryModel).then((data) => {
          categoriesStore.replaceCategory(data as CategoryModel);
          modalStore.resetState();
          ToastService.success("Kategorie erfolgreich aktualisiert.");
        });
      };
      createCallback = () => {
        ModelService.createCategory(editObj as CategoryModel).then((data) => {
          categoriesStore.addCategory(data as CategoryModel);
          modalStore.resetState();
          ToastService.success("Kategorie erfolgreich erstellt.");
        });
      };
      deleteCallback = () => {
        let editCopy = editObj as CategoryModel;
        ModelService.deleteCategory(
          plainToInstance(CategoryModel, {
            ...editCopy,
            _id: editCopy!.id,
          }),
        ).then((data) => {
          categoriesStore.deleteCategory(data as CategoryModel);
          modalStore.resetState();
          ToastService.success("Kategorie erfolgreich entfernt.");
        });
      };
      break;
    case "SubcategoryModel":
      titleStr = "Subkategorie";
      body = (
        <SubcategoryModalBody
          editObj={editObj as SubcategoryModel}
          setEditObj={setEditObj}
        />
      );
      saveCallback = () => {
        ModelService.updateSubcategory(editObj as SubcategoryModel).then(
          (data) => {
            console.log(data);
            categoriesStore.replaceSubcategory(editObj as SubcategoryModel);
            modalStore.resetState();
            ToastService.success("Subkategorie erfolgreich aktualisiert.");
          },
        );
      };
      createCallback = () => {
        ModelService.createSubcategory(editObj as SubcategoryModel).then(
          (data) => {
            categoriesStore.addSubcategory(data as SubcategoryModel);
            modalStore.resetState();
            ToastService.success("Subkategorie erfolgreich erstellt.");
          },
        );
      };
      deleteCallback = () => {
        let editCopy = editObj as SubcategoryModel;
        ModelService.deleteSubcategory(
          plainToInstance(SubcategoryModel, {
            ...editCopy,
            _id: editCopy!.id,
            category_id: editCopy!.categoryId,
          }),
        ).then((data) => {
          categoriesStore.deleteSubcategory(data as SubcategoryModel);
          modalStore.resetState();
          ToastService.success("Subkategorie erfolgreich entfernt.");
        });
      };
      break;
    case "TextBlockModel":
      titleStr = "Textblock";
      body = (
        <TextBlockModalBody
          editObj={editObj as TextBlockModel}
          setEditObj={setEditObj}
        />
      );
      saveCallback = () => {
        ModelService.updateTextBlock(editObj as TextBlockModel).then((data) => {
          categoriesStore.replaceTextBlock(data as TextBlockModel);
          modalStore.resetState();
          ToastService.success("Textblock erfolgreich aktualisiert.");
        });
      };
      createCallback = () => {
        ModelService.createTextBlock(editObj as TextBlockModel).then((data) => {
          categoriesStore.addTextBlock(data as TextBlockModel);
          modalStore.resetState();
          ToastService.success("Textblock erfolgreich erstellt.");
        });
      };
      deleteCallback = () => {
        let editCopy = editObj as TextBlockModel;
        ModelService.deleteTextBlock(
          plainToInstance(TextBlockModel, {
            ...editCopy,
            _id: editCopy!.id,
            subcategory_id: editCopy!.subcategoryId,
          }),
        ).then((data) => {
          categoriesStore.deleteTextBlock(data as TextBlockModel);
          modalStore.resetState();
          ToastService.success("Textblock erfolgreich entfernt.");
        });
      };
      break;
  }

  if (!editObj!.id) {
    saveCallback = createCallback;
    deleteCallback = undefined;
  }

  title = (
    <h5 className="modal-title">{`${titleStr} ${modalObj!.id ? "bearbeiten" : "anlegen"}`}</h5>
  );

  return { title, body, saveCallback, deleteCallback };
}
