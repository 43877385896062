import { ModalState, modalStore } from "../../store/modal.store";
import { modalFactory } from "./modal-factory";
import ModalComponent from "../shared/modal-component";
import { useState } from "react";

export default function ModalContainer({
  modalState,
}: {
  modalState: ModalState;
}) {
  const [editObj, setEditObj] = useState(modalState.modalObj);

  const modal = modalFactory(modalState, editObj, setEditObj);

  return (
    <ModalComponent
      modalTitle={modal.title}
      modalBody={modal.body!}
      saveCallback={modal.saveCallback!}
      closeCallback={modalStore.resetState}
      deleteCallback={modal.deleteCallback}
    />
  );
}
