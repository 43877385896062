import SubcategoriesTable from "./subcategories-table.component";
import NothingToDisplay from "./shared/nothing-to-display.component";
import CategoryModel from "../models/category.model";
import StringUtilsService from "../services/string-utils.service";
import { DetailedHTMLProps } from "react";
import EditBtn from "./shared/edit-btn.component";

function CategoriesList({
  categories = [],
  searchString = "",
  editMode = false,
}: {
  categories?: CategoryModel[];
  searchString?: string;
  editMode: boolean;
}) {
  if (categories?.length === 0) {
    return <NothingToDisplay />;
  }

  function getHighlightedCategoryName(
    category: CategoryModel,
    stringToHighlight: string,
  ): DetailedHTMLProps<any, any> {
    if (stringToHighlight === "") {
      return { __html: category.name };
    }
    return {
      __html: StringUtilsService.getHighlightedString(
        category.name,
        stringToHighlight,
      ),
    };
  }

  return (
    <>
      <div className="accordion" id="categoryAccordion">
        {categories.map((category) => {
          return (
            <div className="accordion-item" key={category.id}>
              <h2 className="accordion-header">
                <div className="row">
                  {editMode && (
                    <EditBtn
                      modalObj={
                        {
                          ...category,
                          className: "CategoryModel",
                        } as CategoryModel
                      }
                    />
                  )}
                  <div className="col">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#cat_${category.id}`}
                    >
                      <span
                        dangerouslySetInnerHTML={getHighlightedCategoryName(
                          category,
                          searchString,
                        )}
                      ></span>
                    </button>
                  </div>
                </div>
              </h2>

              <div
                id={`cat_${category.id}`}
                className={`accordion-collapse collapse ${searchString ? "show" : ""}`}
                data-bs-parent="#categoryAccordion"
              >
                <div className="accordion-body" style={{ padding: "0.5rem" }}>
                  <SubcategoriesTable
                    subcategories={category.subcategories}
                    categoryId={category.id}
                    searchString={searchString}
                    editMode={editMode}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default CategoriesList;
