import TextBlockModel from "../../models/text-block.model";
import React from "react";

import NothingToDisplay from "../shared/nothing-to-display.component";
import CreateBtn from "../shared/create-btn.component";
import TextBlockRow from "./text-block-row.component";
import {v4} from "uuid";
import {favoritesStore} from "../../store/favorites";

function TextBlockList({
  textBlocks = [],
  subcategoryId,
  searchString = "",
  editMode = false,
}: {
  textBlocks: TextBlockModel[];
  subcategoryId?: string;
  searchString?: string;
  editMode: boolean;
}) {
  if (textBlocks?.length === 0) {
    return (
      <div className="text-center">
        <NothingToDisplay />
        {editMode && (
          <CreateBtn
            text={"+ Textblock"}
            newObj={new TextBlockModel("", "", subcategoryId)}
          />
        )}
      </div>
    );
  }
  let currentFavorite = favoritesStore.getCurrentFavorite();
  return (
    <>
      <div className="mt-1">
        {textBlocks.map((textBlock: TextBlockModel, index: number) => (
          <TextBlockRow
            textBlock={textBlock}
            searchString={searchString}
            editMode={editMode}
            isFavorite={currentFavorite != null && currentFavorite.textBlockIds.some(tb => tb.id === textBlock.id)}
            key={v4()}
          />
        ))}
        {editMode && (
          <CreateBtn
            text={"+ Textblock"}
            newObj={new TextBlockModel("", "", subcategoryId)}
          />
        )}
      </div>
    </>
  );
}

export default TextBlockList;
