import { BehaviorSubject, first, map, Observable } from "rxjs";
import { fromPromise } from "rxjs/internal/observable/innerFrom";

export default class ClipboardService {
  static isModernAPIAvailable(): boolean {
    return (
      "clipboard" in navigator && navigator.clipboard.writeText !== undefined
    );
  }

  static copyToClipboard(textToCopy: string): Observable<boolean> {
    if (!this.isModernAPIAvailable()) {
      const subject = new BehaviorSubject(false);
      return subject.pipe(first());
    }
    return fromPromise(navigator?.clipboard?.writeText(textToCopy)).pipe(
      map(() => true),
      first(),
    );
  }
}
