import { Subject } from "rxjs";
import CategoryModel from "../models/category.model";
import SubcategoryModel from "../models/subcategory.model";
import TextBlockModel from "../models/text-block.model";

const subject = new Subject<ModalState>();

export interface ModalState {
  modalObj: CategoryModel | SubcategoryModel | TextBlockModel | null;
}

const initialState: ModalState = {
  modalObj: null,
};

let state = initialState;

export const modalStore = {
  subscribe: (
    setModalState: React.Dispatch<React.SetStateAction<ModalState>>,
  ) => subject.subscribe((state) => setModalState(state)),
  initialState,
  setModalObj: (
    modalObj: CategoryModel | SubcategoryModel | TextBlockModel | null,
  ) => {
    state = {
      modalObj: modalObj,
    };
    subject.next(state);
  },
  resetState: () => {
    state = {
      modalObj: null,
    };
    subject.next(state);
  },
};
