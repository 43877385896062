import "./App.scss";

import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import App from './App';

function Authentication() {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading && !auth.error) {
      void auth.signinRedirect();
    }
  }, [auth]);

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    return <App />;
  }

  // If none of the above conditions are met, it means authentication is in progress.
  // You might render a loading spinner or another appropriate UI element here.
  return <div>Authenticating...</div>;
}

export default Authentication;
