import FavoriteModel from "../../models/favorite.model";
import TextBlockList from "../text-blocks/text-block-list.component";

function FavoritesList({
  favorite,
}: {
  favorite: FavoriteModel | null;
}) {

  return (
    <>
      <div className="accordion" id="favAccordion">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <div className="row">
              <div className="col">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#fav`}
                >
                  <span>Favoriten</span>
                </button>
              </div>
            </div>
          </h2>

          <div
            id={`fav`}
            className={`accordion-collapse collapse`}
            data-bs-parent="#favAccordion"
          >
            <div className="accordion-body" style={{padding: "0.5rem"}}>
              <div className="container border-bottom text-start">
                <div className="row fw-bold text-bg-secondary opacity-50">
                  <div className="col-4">Titel</div>
                  <div className="col-8">Text</div>
                </div>
              <TextBlockList textBlocks={favorite?.textBlockIds!} editMode={false}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FavoritesList;
