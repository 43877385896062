import { Subject } from "rxjs";
import CategoryModel from "../models/category.model";
import SubcategoryModel from "../models/subcategory.model";
import TextBlockModel from "../models/text-block.model";

const subject = new Subject<CategoryState>();

interface CategoryState {
  categories: CategoryModel[];
  allCategories: CategoryModel[];
}

const initialState: CategoryState = {
  categories: [],
  allCategories: [],
};

let state = initialState;

export const categoriesStore = {
  subscribe: (
    setCategoriesState: React.Dispatch<React.SetStateAction<CategoryState>>,
  ) => subject.subscribe((state) => setCategoriesState(state)),
  initialState,
  setAllCategories: (categories: CategoryModel[]) => {
    state = {
      ...state,
      allCategories: [...categories],
    };
    subject.next(state);
  },
  setCategories: (categories: CategoryModel[]) => {
    state = {
      ...state,
      categories: [...categories],
    };
    subject.next(state);
  },
  replaceCategory: (category: CategoryModel) => {
    state = {
      ...state,
      categories: state.categories.map((x) =>
        x.id === category.id ? category : x,
      ),
      allCategories: state.allCategories.map((x) =>
        x.id === category.id ? category : x,
      ),
    };
    subject.next(state);
  },
  addCategory: (category: CategoryModel) => {
    state = {
      ...state,
      categories: [...state.categories, category],
      allCategories: [...state.allCategories, category],
    };
    subject.next(state);
  },
  deleteCategory: (category: CategoryModel) => {
    state = {
      ...state,
      categories: state.categories.filter((x) => x.id !== category.id),
      allCategories: state.allCategories.filter((x) => x.id !== category.id),
    };
    subject.next(state);
  },
  replaceSubcategory: (subcategory: SubcategoryModel) => {
    state = {
      ...state,
      categories: state.categories.map((x) => {
        if (subcategory.categoryId === x.id) {
          x.subcategories = x.subcategories.map((y) =>
            y.id === subcategory.id ? subcategory : y,
          );
        }
        return x;
      }),
    };
    subject.next(state);
  },
  addSubcategory: (subcategory: SubcategoryModel) => {
    state = {
      ...state,
      categories: state.categories.map((x) => {
        if (subcategory.categoryId === x.id) {
          x.subcategories = [...x.subcategories, subcategory];
        }
        return x;
      }),
    };
    subject.next(state);
  },
  deleteSubcategory: (subcategory: SubcategoryModel) => {
    state = {
      ...state,
      categories: state.categories.map((x) => {
        if (subcategory.categoryId === x.id) {
          x.subcategories = x.subcategories.filter(
            (y) => y.id !== subcategory.id,
          );
        }
        return x;
      }),
    };
    subject.next(state);
  },
  replaceTextBlock: (textBlock: TextBlockModel) => {
    state = {
      ...state,
      categories: state.categories.map((x) => {
        x.subcategories = x.subcategories.map((y) => {
          y.textBlocks = y.textBlocks.map((z) =>
            z.id === textBlock.id ? textBlock : z,
          );
          return y;
        });
        return x;
      }),
    };
    subject.next(state);
  },
  addTextBlock: (textBlock: TextBlockModel) => {
    state = {
      ...state,
      categories: state.categories.map((x) => {
        x.subcategories = x.subcategories.map((y) => {
          if (y.id === textBlock.subcategoryId) {
            y.textBlocks = [...y.textBlocks, textBlock];
          }
          return y;
        });
        return x;
      }),
    };
    subject.next(state);
  },
  deleteTextBlock: (textBlock: TextBlockModel) => {
    state = {
      ...state,
      categories: state.categories.map((x) => {
        x.subcategories = x.subcategories.map((y) => {
          y.textBlocks = y.textBlocks.filter((z) => z.id !== textBlock.id);
          return y;
        });
        return x;
      }),
    };
    subject.next(state);
  },
};
