import TextBlockModel from "../../models/text-block.model";
import React, {createRef, DetailedHTMLProps, useEffect, useLayoutEffect, useState,} from "react";
import StringUtilsService from "../../services/string-utils.service";
import ClipboardService from "../../services/clipboard.service";
import ToastService from "../../services/toast.service";
import EditBtn from "../shared/edit-btn.component";
import {textBlocksEditorStore} from "../../state/text-blocks/text-blocks-editor.store";
import {Popover, Tooltip} from "bootstrap";
import {useIsVisible} from "../../helpers/is-visible.hook";
import FavoriteBtn from "../favorites/favorite-btn.component";

function TextBlockRow({
  textBlock,
  searchString = "",
  isFavorite = false,
  editMode = false,
}: {
  textBlock: TextBlockModel;
  searchString?: string;
  isFavorite: boolean;
  editMode: boolean;
}) {
  const textElementRef = createRef<HTMLDivElement>();
  const rootElementRef = createRef<HTMLDivElement>();
  const containerRef = createRef<HTMLDivElement>();

  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const isVisible = useIsVisible(containerRef);

  // Called once after the DOM is ready.
  useEffect(() => {
    // Init popovers in this component once again, as they are not in DOM when App.tsx should initialize them.
    const popoverTriggerList: NodeListOf<HTMLElement> =
      rootElementRef.current!.querySelectorAll('[data-bs-toggle="popover"]');
    const popoverList: Popover[] = [...popoverTriggerList].map(
      (popoverTriggerEl) => new Popover(popoverTriggerEl),
    );

    // Init tooltips in this component once again, as they are not in DOM when App.tsx should initialize them.
    const tooltipTriggerList: NodeListOf<HTMLElement> =
      rootElementRef.current!.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList: Tooltip[] = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl),
    );
  }, []);

  useLayoutEffect(() => {
    const element: HTMLSpanElement = textElementRef.current as HTMLSpanElement;
    const container: HTMLDivElement = containerRef.current as HTMLDivElement;
    const isTextTooLong: boolean = container.offsetWidth < element.scrollWidth;
    setShowMoreButton(isTextTooLong);
  }, [isVisible]);

  function getHighlightedTextBlockTitle(
    textBlock: TextBlockModel,
    stringToHighlight: string,
  ): DetailedHTMLProps<any, any> {
    if (stringToHighlight === "") {
      return { __html: textBlock.title };
    }
    return {
      __html: StringUtilsService.getHighlightedString(
        textBlock.title,
        stringToHighlight,
      ),
    };
  }

  function getHighlightedTextBlockText(
    textBlock: TextBlockModel,
    stringToHighlight: string,
  ): DetailedHTMLProps<any, any> {
    if (stringToHighlight === "") {
      return { __html: textBlock.text };
    }
    return {
      __html: StringUtilsService.getHighlightedString(
        textBlock.text,
        stringToHighlight,
      ),
    };
  }

  async function copyTextBlockText(textBlock: TextBlockModel) {
    ClipboardService.copyToClipboard(textBlock.text).subscribe(
      (result: boolean) => {
        if (result) {
          ToastService.success(`Inhalt wurde kopiert!`);
        } else {
          ToastService.error(`Inhalt konnte nicht kopiert werden!`);
        }
      },
    );
  }

  function addTextBlockToEditor(textBlock: TextBlockModel) {
    textBlocksEditorStore.addTextBlock(textBlock);
  }

  return (
    <>
      <div className="row mb-1" key={textBlock.id} ref={rootElementRef}>
        <div className="col-4">
          <div className="d-flex align-items-center flex-nowrap">
            {editMode && (
              <>
                <div className="flex-shrink-1 pl-0 pr-0">
                  <EditBtn
                    modalObj={
                      {
                        ...textBlock,
                        className: "TextBlockModel",
                      } as TextBlockModel
                    }
                    padding={"px-0"}
                  />
                </div>
              </>
            )}
            <div
              className="flex-shrink-2 pl-0 pr-0"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              <span
                dangerouslySetInnerHTML={getHighlightedTextBlockTitle(
                  textBlock,
                  searchString,
                )}
              ></span>
            </div>
          </div>
        </div>
        <div className="col-8 row" ref={containerRef}>
          <div className="col-10 d-flex gx-0 align-items-center">
            <span
              className="d-block"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              ref={textElementRef}
              dangerouslySetInnerHTML={getHighlightedTextBlockText(
                textBlock,
                searchString,
              )}
            ></span>
            <a
              className="btn btn-sm pl-2"
              style={{ display: showMoreButton ? "block" : "none" }}
              data-bs-toggle="popover"
              data-bs-placement="top"
              data-bs-content={textBlock.text}
            >
              Mehr
            </a>
          </div>
          <div className="col-2 mt-auto mb-auto">
            <div className="btn-group btn-group-sm">
              <button
                  type="button"
                  className="btn btn-sm btn-outline-success"
                  data-bs-toggle="tooltip"
                  data-bs-title="Text kopieren"
                  onClick={() => copyTextBlockText(textBlock)}
              >
                <i className="bi-clipboard"/>
              </button>
              <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary"
                  data-bs-toggle="tooltip"
                  data-bs-title="Zu Editor hinzufügen"
                  onClick={() => addTextBlockToEditor(textBlock)}
              >
                <i className="bi-arrow-right"></i>
              </button>
              <FavoriteBtn textBlock={textBlock} isFav={isFavorite} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TextBlockRow;
