import {Expose, Type} from "class-transformer";
import BaseModel from "./base.model";
import SubcategoryModel from "./subcategory.model";

/**
 * Class represents a category.
 * @extends BaseModel
 */
export default class CategoryModel extends BaseModel {
  className = "CategoryModel";

  @Expose()
  name = "";

  @Expose()
  @Type(() => SubcategoryModel)
  subcategories: SubcategoryModel[] = [];
}
