import TextBlockList from "./text-blocks/text-block-list.component";
import NothingToDisplay from "./shared/nothing-to-display.component";
import SubcategoryModel from "../models/subcategory.model";
import { DetailedHTMLProps } from "react";
import StringUtilsService from "../services/string-utils.service";
import CreateBtn from "./shared/create-btn.component";
import EditBtn from "./shared/edit-btn.component";

function SubcategoriesTable({
  subcategories = [],
  categoryId,
  searchString = "",
  editMode = false,
}: {
  subcategories?: SubcategoryModel[];
  categoryId?: string;
  searchString?: string;
  editMode: boolean;
}) {
  if (subcategories?.length === 0) {
    return (
      <>
        <NothingToDisplay />
        {editMode && (
          <CreateBtn
            text={"+ Subkategorie"}
            newObj={new SubcategoryModel("", categoryId)}
          />
        )}
      </>
    );
  }

  function getHighlightedSubcategoryName(
    subcategory: SubcategoryModel,
    stringToHighlight: string,
  ): DetailedHTMLProps<any, any> {
    if (stringToHighlight === "") {
      return { __html: subcategory.name };
    }
    return {
      __html: StringUtilsService.getHighlightedString(
        subcategory.name,
        stringToHighlight,
      ),
    };
  }

  return (
    <>
      {subcategories.map((subcategory) => (
        <div className="caption-top mb-2 text-start" key={subcategory.id}>
          <div className="row d-flex align-items-center">
            {editMode && (
              <EditBtn
                modalObj={
                  {
                    ...subcategory,
                    className: "SubcategoryModel",
                  } as SubcategoryModel
                }
                padding={"pe-0"}
                verticalAlign={"text-bottom"}
              />
            )}
            <div className="col-auto">
              <h5>
                <span
                  dangerouslySetInnerHTML={getHighlightedSubcategoryName(
                    subcategory,
                    searchString,
                  )}
                ></span>
              </h5>
            </div>
          </div>
          <div className="container border-bottom">
            <div className="row fw-bold text-bg-secondary opacity-50">
              <div className="col-4">Titel</div>
              <div className="col-8">Text</div>
            </div>
            <TextBlockList
              textBlocks={subcategory.textBlocks}
              subcategoryId={subcategory.id}
              searchString={searchString}
              editMode={editMode}
            />
          </div>
        </div>
      ))}
      {editMode && (
        <CreateBtn
          text={"+ Subkategorie"}
          newObj={new SubcategoryModel("", categoryId)}
        />
      )}
    </>
  );
}

export default SubcategoriesTable;
