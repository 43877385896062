import { toast } from "react-toastify";

export default class ToastService {
  static info(toastContent: string) {
    toast.info(toastContent, { theme: "colored" });
  }

  static success(toastContent: string) {
    toast.success(toastContent, { theme: "colored" });
  }

  static warn(toastContent: string) {
    toast.warn(toastContent, { theme: "colored" });
  }

  static error(toastContent: string) {
    toast.error(toastContent, { theme: "colored" });
  }
}
