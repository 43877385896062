import DeleteQuestion from "./delete-question.component";
import { useState } from "react";

export default function ModalComponent({
  modalTitle,
  modalBody,
  saveCallback,
  closeCallback,
  deleteCallback = undefined,
}: {
  modalTitle: JSX.Element;
  modalBody: JSX.Element;
  saveCallback: Function;
  closeCallback: React.MouseEventHandler<HTMLButtonElement>;
  deleteCallback: Function | undefined;
}) {
  const [showDeleteQuestion, setShowDeleteQuestion] = useState(false);
  return (
    <div className="modal d-block">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            {modalTitle}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeCallback}
            ></button>
          </div>
          <div className="modal-body">{modalBody}</div>
          <div className="modal-footer">
            {deleteCallback && (
              <>
                {showDeleteQuestion && (
                  <DeleteQuestion
                    setShowDeleteQuestion={setShowDeleteQuestion}
                    deleteCallback={deleteCallback}
                  />
                )}
                <button
                  className="btn me-auto btn-outline-danger"
                  style={{ verticalAlign: "baseline" }}
                  onClick={() => setShowDeleteQuestion(true)}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </>
            )}
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={closeCallback}
            >
              Abbrechen
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                saveCallback();
              }}
            >
              Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
