import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import ToastService from "../../services/toast.service";

interface ProtectedRouteProps {
  isAllowed: boolean;
  redirectPath?: string;
  children?: React.ReactNode;
}

const ProtectedRoute = ({
  isAllowed,
  redirectPath = "/",
  children,
}: ProtectedRouteProps) => {
  if (!isAllowed) {
    ToastService.warn("Keine Berechtigung diese Seite aufzurufen.");
    return <Navigate to={redirectPath} replace />;
  }
  return <>{children || <Outlet />}</>;
};

export default ProtectedRoute;
