import { DialogConfig } from "../../state/dialog/dialog.store";

export default function DialogComponent({
  dialogConfig,
  displayDialog = true,
}: {
  dialogConfig: DialogConfig;
  displayDialog: boolean;
}) {
  function onSubmit() {
    if (dialogConfig?.submitCallback) {
      dialogConfig.submitCallback();
    }
  }

  function onCancel() {
    if (dialogConfig?.cancelCallback) {
      dialogConfig.cancelCallback();
    }
  }

  return (
    <>
      <div className="modal d-block" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              {dialogConfig?.title && (
                <h5 className="modal-title">{dialogConfig.title}</h5>
              )}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {dialogConfig?.body && (
              <div className="modal-body">{dialogConfig.body}</div>
            )}
            <div className="modal-footer">
              {dialogConfig?.submitCallback && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onSubmit}
                >
                  {dialogConfig?.submitLabel}
                </button>
              )}
              {dialogConfig?.cancelCallback && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={onCancel}
                >
                  {dialogConfig?.cancelLabel}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
