import { textBlocksEditorStore } from "./text-blocks-editor.store";
import DialogService from "../dialog/dialog.service";

export default class TextBlocksEditorService {
  static clearStore(showConfirm: boolean = false) {
    if (showConfirm) {
      DialogService.showConfirm({
        titleString: "Textblöcke löschen",
        questionString: "Wollen Sie wirklich alle Textbausteine löschen?",
        onSubmit: () => textBlocksEditorStore.clearTextBlocks(),
        onCancel: () => {},
      });
    } else {
      textBlocksEditorStore.clearTextBlocks();
    }
  }

  /**
   * TODO: Remove if not needed anymore.
   *
   * @param id
   * @param showConfirm
   */
  static dropTextBlockById(id: string, showConfirm: boolean = false) {
    if (showConfirm) {
      DialogService.showConfirm({
        onSubmit: () => textBlocksEditorStore.dropTextBlockById(id),
        onCancel: () => {},
      });
    } else {
      textBlocksEditorStore.dropTextBlockById(id);
    }
  }

  static dropTextBlockByIndex(index: number, showConfirm: boolean = false) {
    if (showConfirm) {
      DialogService.showConfirm({
        titleString: "Textblock löschen",
        questionString: "Wollen Sie den Textbaustein wirklich löschen?",
        onSubmit: () => textBlocksEditorStore.dropTextBlockByIndex(index),
        onCancel: () => {},
      });
    } else {
      textBlocksEditorStore.dropTextBlockByIndex(index);
    }
  }

  static moveTextBlock(fromIndex: number, toIndex: number) {
    textBlocksEditorStore.moveTextBlock(fromIndex, toIndex);
  }
}
