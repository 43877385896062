import {Expose} from "class-transformer";
import BaseModel from "./base.model";

/**
 * Class represents a favorite api model.
 * @extends BaseModel
 */
export default class FavoriteApiModel extends BaseModel {
  constructor(userId: string, textBlockId: string) {
    super();
    this.userId = userId;
    this.textBlockId = textBlockId;
  }
  className = "FavoriteApiModel";

  @Expose({ name: "userId" })
  userId = "";

  @Expose()
  textBlockId = "";
}
