import { modalStore } from "../../store/modal.store";
import CategoryModel from "../../models/category.model";
import SubcategoryModel from "../../models/subcategory.model";
import TextBlockModel from "../../models/text-block.model";

export default function EditBtn({
  modalObj,
  padding = "pe-0",
  verticalAlign = "baseline",
}: {
  modalObj: CategoryModel | SubcategoryModel | TextBlockModel;
  padding?: string;
  verticalAlign?: string;
}) {
  return (
    <div className={`col-auto ${padding}`}>
      <button
        className="btn"
        style={{ verticalAlign: verticalAlign }}
        onClick={() => modalStore.setModalObj(modalObj)}
      >
        <i className="bi bi-pencil" />
      </button>
    </div>
  );
}
