import ApiService from "./api.service";
import CategoryModel from "../models/category.model";
import BaseModel from "../models/base.model";
import SubcategoryModel from "../models/subcategory.model";
import TextBlockModel from "../models/text-block.model";
import StringUtilsService from "./string-utils.service";
import FavoriteModel from "../models/favorite.model";
import FavoriteApiModel from "../models/favorite.api.model";

export default class ModelService {
  private static deserializeModel<T extends BaseModel>(
    data: Object | Object[],
    classRef: typeof BaseModel,
  ): T | T[] {
    return Array.isArray(data)
      ? ((data as Object[]).map(classRef.fromJSON) as T[])
      : (classRef.fromJSON(data) as T);
  }

  static getAllCategories() {
    return ApiService.getAllCategories().then((data) =>
      this.deserializeModel(data, CategoryModel),
    ) as Promise<CategoryModel[]>;
  }

  static getAllCategoriesPopulated() {
    return ApiService.getAllCategoriesPopulated().then((data) =>
      this.deserializeModel(data, CategoryModel),
    );
  }

  static getAllCategoriesPopulatedFiltered(
    searchString: String,
  ): Promise<CategoryModel[]> {
    return ApiService.getAllCategoriesPopulatedFiltered(searchString).then(
      (data) => this.deserializeModel(data, CategoryModel),
    ) as Promise<CategoryModel[]>;
  }

  static getAllCategoriesPopulatedFilteredShowSubcategories(
    searchString: String,
    searchContextCategories: String[],
  ): Promise<CategoryModel[]> {
    return ApiService.getAllCategoriesPopulatedFilteredShowSubcategories(
      searchString,
      searchContextCategories,
    ).then((data) => this.deserializeModel(data, CategoryModel)) as Promise<
      CategoryModel[]
    >;
  }

  static updateCategory(category: CategoryModel) {
    category = StringUtilsService.trimObjectAttributes(category, ["name"]);
    return ApiService.updateCategory(category).then((data) =>
      this.deserializeModel(data, CategoryModel),
    );
  }

  static createCategory(category: CategoryModel) {
    category = StringUtilsService.trimObjectAttributes(category, ["name"]);
    return ApiService.createCategory(category).then((data) =>
      this.deserializeModel(data, CategoryModel),
    );
  }

  static deleteCategory(category: CategoryModel) {
    return ApiService.deleteCategory(category).then((data) =>
      this.deserializeModel(data, CategoryModel),
    );
  }

  static updateSubcategory(subcategory: SubcategoryModel) {
    subcategory = StringUtilsService.trimObjectAttributes(subcategory, [
      "name",
    ]);
    return ApiService.updateSubcategory(subcategory).then((data) =>
      this.deserializeModel(data, SubcategoryModel),
    );
  }

  static createSubcategory(subcategory: SubcategoryModel) {
    subcategory = StringUtilsService.trimObjectAttributes(subcategory, [
      "name",
    ]);
    return ApiService.createSubcategory(subcategory).then((data) =>
      this.deserializeModel(data, SubcategoryModel),
    );
  }

  static deleteSubcategory(subcategory: SubcategoryModel) {
    return ApiService.deleteSubcategory(subcategory).then((data) =>
      this.deserializeModel(data, SubcategoryModel),
    );
  }

  static createTextBlock(textBlock: TextBlockModel) {
    textBlock = StringUtilsService.trimObjectAttributes(textBlock, [
      "text",
      "title",
    ]);
    return ApiService.createTextBlock(textBlock).then((data) =>
      this.deserializeModel(data, TextBlockModel),
    );
  }

  static updateTextBlock(textBlock: TextBlockModel) {
    textBlock = StringUtilsService.trimObjectAttributes(textBlock, [
      "text",
      "title",
    ]);
    return ApiService.updateTextBlock(textBlock).then((data) =>
      this.deserializeModel(data, TextBlockModel),
    );
  }

  static deleteTextBlock(textBlock: TextBlockModel) {
    return ApiService.deleteTextBlock(textBlock).then((data) =>
      this.deserializeModel(data, TextBlockModel),
    );
  }

  static getFavoriteByUserId(
      userId: string,
  ): Promise<FavoriteModel> {
    return ApiService.getFavoriteByUserId(
        userId
    ).then((data) => this.deserializeModel(data, FavoriteModel)) as Promise<FavoriteModel>;
  }

  static addOrUpdateFavorite(favorite: FavoriteApiModel) {
    return ApiService.addOrUpdateFavorite(favorite).then((data) =>
        this.deserializeModel(data, FavoriteModel),
    );
  }

  static deleteFavoriteByUserIdAndTextBlockId(favorite: FavoriteApiModel) {
    return ApiService.deleteFavoriteByUserIdAndTextBlockId(favorite).then((data) =>
        this.deserializeModel(data, FavoriteModel),
    );
  }
  /*static getAllSubcategories() {
      return ApiService.getAllSubcategories()
        .then(this.deserializeModel);
    }
  
    static getAllTextBlocks() {
      return ApiService.getAllTextBlocks()
        .then(this.deserializeModel);
    }
    */
}
