import CategoryModel from "../../../models/category.model";

export default function CategoryModalBody({
  editObj,
  setEditObj,
}: {
  editObj: CategoryModel;
  setEditObj: Function;
}) {
  return (
    <div className="input-group mb-3">
      <span className="input-group-text" id="basic-addon1">
        Name
      </span>
      <input
        type="text"
        className="form-control"
        placeholder="Kategorie"
        value={editObj.name}
        onChange={(e) =>
          setEditObj({ ...editObj, name: e.target.value } as CategoryModel)
        }
      />
    </div>
  );
}
