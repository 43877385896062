import BaseModel from "./base.model";
import { Expose } from "class-transformer";

export default class TextBlockModel extends BaseModel {
  constructor(title: string = "", text: string = "", subcategoryId?: string) {
    super();
    this.title = title;
    this.text = text;
    this.subcategoryId = subcategoryId;
  }

  className = "TextBlockModel";

  @Expose()
  title = "";

  @Expose()
  text = "";

  @Expose({ name: "subcategory_id" })
  subcategoryId?: string;
}
