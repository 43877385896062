import ColorService from "../../services/color.service";
import React, { useRef } from "react";
import TextBlockModel from "../../models/text-block.model";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import { DraggableTypes } from "../../models/draggable-types.model";
import ClipboardService from "../../services/clipboard.service";
import ToastService from "../../services/toast.service";

export type DragItem = {
  index: number;
  type: string;
  textBlock: TextBlockModel;
};

function TextBlockDraggableComponent({
  textBlock,
  index,
  moveTextBlock,
  isExtended = false,
}: {
  textBlock: TextBlockModel;
  index: number;
  moveTextBlock: (dragIndex: number, hoverIndex: number) => void;
  isExtended?: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: DraggableTypes.textBlock,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveTextBlock(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DraggableTypes.textBlock,
    item: () => {
      return { textBlock, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));

  const titleToDisplay: string = textBlock.title
    ? textBlock.title
    : "Kein Titel";
  const backgroundColor: string =
    ColorService.getHexColorByString(titleToDisplay);

  async function copyTextBlockText(textBlock: TextBlockModel) {
    ClipboardService.copyToClipboard(textBlock.text).subscribe(
      (result: boolean) => {
        if (result) {
          ToastService.success(`Inhalt wurde kopiert!`);
        } else {
          ToastService.error(`Inhalt konnte nicht kopiert werden!`);
        }
      },
    );
  }

  // TODO(FH): Make the animation only work once (when element is added to editor, not every update).
  return (
    <>
      <div
        className="m-1 p-1 rounded me-1 grabable text-wrap p-absolute text-block--wrapper"
        ref={ref}
        data-handler-id={handlerId}
        style={{
          background: backgroundColor,
          opacity: opacity,
        }}
      >
        <div className="text-block--menu-bar w-100">
          <div
            style={{
              background: backgroundColor,
            }}
            className="p-1 rounded-top float-end"
          >
            <button
              type="button"
              className="btn btn-sm btn-outline-success"
              data-bs-toggle="tooltip"
              data-bs-title="Text kopieren"
              onClick={() => copyTextBlockText(textBlock)}
            >
              <i className="bi-clipboard" />
            </button>
          </div>
        </div>
        {isExtended ? textBlock.text : titleToDisplay}
      </div>
    </>
  );
}

export default TextBlockDraggableComponent;
