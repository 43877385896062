import {Expose, Type} from "class-transformer";
import BaseModel from "./base.model";
import TextBlockModel from "./text-block.model";

/**
 * Class represents a subcategory.
 * @extends BaseModel
 */
export default class SubcategoryModel extends BaseModel {
  constructor(name?: string, categoryId?: string) {
    super();
    this.categoryId = categoryId;
  }

  className = "SubcategoryModel";

  @Expose()
  name = "";

  @Expose({ name: "category_id" })
  categoryId?: string;

  @Expose()
  @Type(() => TextBlockModel)
  textBlocks: TextBlockModel[] = [];
}
