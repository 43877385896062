import {Expose, instanceToPlain, plainToClass} from "class-transformer";

/**
 * Class that can be constructed from a JSON-object.
 */
export default class BaseModel {
  @Expose({ name: "_id" })
  id?: string;

  /**
   * Creates a new instance form a JSON-file.
   *
   * @param jsonDocument {Object}
   * @returns {BaseModel} An instance of the class created from a JSON.
   */
  static fromJSON<T extends BaseModel>(this: new () => T, jsonDocument: Record<string, any>): T {
    return plainToClass(this, jsonDocument, { excludeExtraneousValues: true });
  }

  toJSON() {
    return instanceToPlain(this);
  }
}
