import { JsonObject } from "@cnz.com/ts-json";
import { ChangelogFileModel } from "../models/changelog-file.model";
import changelogFile from "../changelog.json";

export default class ChangelogService {
  private static _instance: ChangelogService;

  private constructor() {}

  public static get instance() {
    if (!ChangelogService._instance) {
      ChangelogService._instance = new ChangelogService();
    }
    return ChangelogService._instance;
  }

  private _changelogFile?: ChangelogFileModel;
  public get changelogFile(): ChangelogFileModel {
    if (!this._changelogFile) {
      this._changelogFile = this.validateChangelogFile(changelogFile);
    }
    return this._changelogFile;
  }

  private validateChangelogFile(json: JsonObject): ChangelogFileModel {
    return json as ChangelogFileModel;

    // TODO: Do real validation! The validator atm creates readonly values which cannot be mutuated 🤨

    // const validator: JsonValidator = new JsonValidator(json);

    /* const validationRules: JsonValidationRules<ChangelogFileModel> = (v: JsonValidator) => ({
            version: v.validate("version", JsonType.string),
            changes: v.validateArrayOfObjects<ChangelogEventModel>("changes", (v: JsonValidator) => ({
                version: v.validate("version", JsonType.string),
                createdAt: v.validate("createdAt", JsonType.string),
                title: v.validate("title", JsonType.string),
                description: v.validate("description", JsonType.string),
                images: v.validateArrayOfObjectsOpt<{ path: string, title: string }>("images", (v: JsonValidator) => ({
                    title: v.validate("title", JsonType.string),
                    path: v.validate("path", JsonType.string)
                }))     
            })),
        });
        try {
            return validationRules(validator);
        } catch (exception: unknown) {
            if (exception instanceof JsonError) {
                console.error(`Invalid JSON file!\n${exception}`);
            } else {
                console.error(`Error\n${exception}`);
            }
        }
        return; */
  }

  public validateVersion(versionString: string): boolean {
    // Regex to match exactly 3 numbers, separated by a '.'-digit, nothing else before and after.
    const versionRegex = /^(\d{1,}.\d{1,}.\d{1,})$/g;
    return versionRegex.test(versionString);
  }

  /**
   * Compares two version strings, returns 1 if a>b, 0 if both are equal and -1 if b>a.
   *
   * @param versionA
   * @param versionB
   */
  public compareVersions(versionA: string, versionB: string): number {
    if (!this.validateVersion(versionA)) {
      throw new Error(
        `Error: versionA (${versionA}) is not a valid version number!`,
      );
    }
    if (!this.validateVersion(versionB)) {
      throw new Error(
        `Error: versionB (${versionB}) is not a valid version number!`,
      );
    }

    return versionA.localeCompare(versionB, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  }

  /**
   * Returns true, if the current version is higher than the provided version string.
   *
   * @param versionString
   * @returns
   */
  public isOutdatedVersion(versionString: string): boolean {
    const currentVersion: string = this._changelogFile?.version!;
    return this.compareVersions(currentVersion, versionString) == 1;
  }
}
