import ChangelogService from "../services/changelog.service";
import {
  ChangelogEventModel,
  ChangelogFileModel,
} from "../models/changelog-file.model";
import NothingToDisplay from "../components/shared/nothing-to-display.component";
import { v4 } from "uuid";
import { useEffect } from "react";
import LocalStorageService from "../services/local-storage.service";

function ChangelogPage() {
  const changelog: ChangelogFileModel = ChangelogService.instance.changelogFile;

  useEffect(() => {
    console.log("changelog");
    const currentVersion: string =
      ChangelogService.instance.changelogFile.version;
    LocalStorageService.instance.storeVersion(currentVersion);
  }, []);

  return (
    <>
      <div className="container-fluid">
        {!changelog && <NothingToDisplay />}
        {changelog && (
          <>
            <ul className="list-group">
              {changelog &&
                changelog.changes.map((change: ChangelogEventModel) => (
                  <li key={v4()} className="list-group-item">
                    <div className="row">
                      <div className="col-4 text-start">
                        <span className="badge rounded-pill bg-info align-text-bottom me-2">
                          {change.version}
                        </span>
                        <span className="fw-bold">{change.title}</span>
                      </div>
                      <div className="col-8 text-start">
                        {change.description}
                      </div>
                      {change.images && change.images.length > 0 && (
                        <div className="ms-2 mt-2 d-flex">
                          {change.images.map(({ path, title }) => (
                            <div
                              key={v4()}
                              className="card me-2"
                              style={{ width: "18rem" }}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/changelog/" +
                                  path
                                }
                                alt={title}
                                className="card-img-top"
                              />
                              <div className="card-body">{title}</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </li>
                ))}
            </ul>
          </>
        )}
      </div>
    </>
  );
}

export default ChangelogPage;
